import React from "react";



const Doc: React.FC = () => {


  return <div style={{ display: "flex", flex: 1 }}>
    <p>Ola Mundo</p>
  </div>
}

export default Doc;