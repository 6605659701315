import { createGlobalStyle } from "styled-components"
import BG from "./assets/bg.svg"
const GlobalStyles = createGlobalStyle`
  :root{
    --color_primary: #2891D5;
  }

  *{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    outline: none;
    font-family: 'Quicksand', sans-serif;
  }
  body{
    width: 100%;
    height: 100vh;
    background-image: url(${BG});
    background-repeat: no-repeat;
    background-size: cover;
  }
`

export default GlobalStyles