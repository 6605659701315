import React from "react";

import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom"

import Home from "./pages/home"
import Doc from "./pages/doc"
const reload = () => window.location.reload();
const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={"/"} exact component={Home} />
        <Route path={"/doc"} exact component={Doc} />
      </Switch>
    </BrowserRouter>
  )
}

export default AppRoutes;