import React from "react"



import {
  useHistory
} from "react-router-dom"

import {
  Container,
  Title,
  ContainerDocs,
  Box
} from "./style"

const Home: React.FC = () => {
  const history = useHistory();

  function goToDoc() {
    history.push("./hkponto.html");

  }

  return (
    <Container>
      <Title>Documentações HK</Title>

      <ContainerDocs>
        <Box active={true}>
          <h1>HKPonto Server</h1>
          <h2>Documentação destinada a API que da suporte aos métodos de ponto</h2>
          <a href={"./hkponto.html"} target={"_blank"}>Conferir</a>
        </Box>
        <Box active={true}>
          <h1>SarServer API</h1>
          <h2>Documentação destinada a API que da suporte aos métodos do SarApp</h2>
          <p>não disponível</p>
        </Box>
        <Box active={true}>
          <h1>HKRonda Server</h1>
          <h2>Documentação destinada a API que da suporte aos métodos de Ronda</h2>
          <p>não disponível</p>
        </Box>
      </ContainerDocs>
    </Container>
  )
}


export default Home;