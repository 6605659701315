import styled from "styled-components"


interface IProps {
  active?: boolean
}


export const Container = styled.div`
  display: flex;
  flex: 1;  
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Title = styled.div`
  font-size: 40px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  align-self: center;
  &::after{
    content: "";
    display: block;
    width: 100px;
    height: 3px;
    background: #fff
  }
`

export const ContainerDocs = styled.div`
  display: grid;
  width: 100%;   
  
  grid-template-columns: repeat(3, 300px);
  grid-gap: 10px;
  margin-top: 60px;
  align-content: center;
  justify-content: center;
  @media(max-width: 980px){
    grid-template-columns: 1fr;  
    justify-items: center;     
  }
`

export const Box = styled.div<IProps>`
  align-self: center;
  width: 280px;
  height: 280px;
  background: #fff;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: relative;
  transition:  all .6s;
  >h1{
    font-size: 24px;
    align-self: center;
    color: rgba(0,0,0,.8);
    font-weight: bold;
  }
  >h2{
    font-size: 12px;
    text-align: center;
    color: #999;
    margin-top: 20px;
  }
  >p{
    position: absolute;
    bottom: 20px;
    align-self: center;
    color: #666;
  }
  >a{
    position: absolute;
    bottom: 20px;
    align-self: center;
    display: block;
    width: 100px;
    padding: 10px;
    border-radius: 20px;
    outline: none;
    border: none;   
    cursor: pointer; 
    text-align: center;
    text-decoration: none;
    background: silver;
    color: rgba(0,0,0,.8); 
    ${({ active }) => {
    if (active) {
      return ` background: var(--color_primary);
      color: #fff;`
    }
  }}
  }



  &:hover{
    transform:translateY(-10px);
    
  }
`